import React from 'react';
import logo from './logo.svg';
import './App.css';
import FirebaseContext from "./app/contexts/FirebaseContext";

function App() {
  return (
    <FirebaseContext>
      <div className="App">
        <header className="App-header">
         
          <p>
           Welcome to Knitr!
          </p>
          
        </header>
      </div>
    </FirebaseContext>
  );
}

export default App;
