// context/firebaseContext.js

import { useState, useEffect, useContext, createContext} from 'react'
import { FirebaseApp, getApp, getApps, initializeApp } from 'firebase/app'
import { Auth, getAuth, User } from 'firebase/auth'
import { Firestore, getFirestore} from 'firebase/firestore'
import { FirebaseStorage, getStorage } from 'firebase/storage'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { credentials } from '../../config/credentials'

interface FirebaseContextProperties {
	app?:FirebaseApp,
	db?: Firestore,
	auth?: Auth,
	analytics?: Analytics,
	storage?: FirebaseStorage,
	setUser?: React.Dispatch<any>,
	user: User | null,
	loadingUser?: boolean
}
export const FirebaseContext = createContext({} as FirebaseContextProperties);

const Context = ({ children }: any) => {
	// The whole app
	const [app, setApp] = useState<FirebaseApp>()
	// Just the DB
	const [db, setDb] = useState<Firestore>()
	// Just the Auth
	const [auth, setAuth] = useState<Auth>()
	// Just the Auth
	const [analytics, setAnalytics] = useState<Analytics>()
	// Just the Storage
	const [storage, setStorage] = useState<FirebaseStorage>()
	// BONUS! Current user
	const [user, setUser] = useState<User|null>(null)
	const [loadingUser, setLoadingUser] = useState(true)

	useEffect(() => {
		// Initialize Firebase
		// This checks if the app is already initialized
		if (!app && !getApps().length) {
			initializeApp(credentials)

			setApp(getApp())
			setAnalytics(getAnalytics())
			setDb(getFirestore())
			setAuth(getAuth())
			setStorage(getStorage())
			// Listen authenticated user
			auth?.onAuthStateChanged(async (user) => {
				try {
					if (user) {
						setUser(user)
						setLoadingUser(false)
					} else {
						setUser(null)
					}
				} catch (error) {
					console.error(error)
				}
			})
		}
	}, [app, analytics, auth])

	return (
		<FirebaseContext.Provider
			value={{ app, db, auth, analytics, storage, user, setUser, loadingUser }}
		>
			{children}
		</FirebaseContext.Provider>
	)
}

export default Context;
export const useFirebase = () => useContext(FirebaseContext)
